<template>
  <a-card :bordered="false" class="card_bottom">
    <a-row type="flex" justify="start">
      <a-col :span="4">
        <info title="已报备" :value="`${reportCount}`" :bordered="true" />
      </a-col>
      <a-col :span="4">
        <info title="已到访" :value="`${visitCount}`" :bordered="true" />
      </a-col>
      <a-col :span="4">
        <info title="已认购" :value="`${subCount}`" :bordered="true" />
      </a-col>
      <a-col :span="4">
        <info title="已签约" :value="`${signCount}`" :bordered="true" />
      </a-col>
      <a-col :span="4">
        <info title="结佣中" :value="`${commissioningCount}`" :bordered="true" />
      </a-col>
      <a-col :span="4">
        <info title="已结佣" :value="`${commissionendCount}`" :bordered="true" />
      </a-col>
    </a-row>
  </a-card>
</template>
<script>
import { Info } from '@/components'
import { clientOrderStatistics } from '@/api/clientOrder'
export default {
  name: 'Cockpit',
  components: {
    Info
  },
  data() {
    return {
      commissionendCount: 0,
      commissioningCount: 0,
      reportCount: 0,
      signCount: 0,
      subCount: 0,
      visitCount: 0
    }
  },
  computed: {
    houseId() {
      return this.$store.state.house.houseId
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  mounted() {
    this.reload()
  },
  methods: {
    reload() {
      const param = {
        developerId: this.userId,
        houseId: this.houseId
      }
      clientOrderStatistics(param).then(res => {
        if (res.success) {
          const data = res.data
          this.commissionendCount = data.commissionendCount
          this.commissioningCount = data.commissioningCount
          this.reportCount = data.reportCount
          this.signCount = data.signCount
          this.subCount = data.subCount
          this.visitCount = data.visitCount
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.sum /deep/ .ant-card-body {
  padding: 0;
}
.card_bottom {
  .ant-col-4 {
    width: 16.6%;
  }
  /deep/ .ant-card-head-title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }
}
</style>
