<template>
  <a-modal 
    title="确认到访" 
    :width="640" 
    :visible="visible" 
    :confirmLoading="loading" 
    :maskClosable="false"
    @ok="handleOk" 
    @cancel="handleCancel">
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="handleOk">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <p>{{ record ? record.clientName : '客户' }}确认到访吗？</p>
    </a-spin>
  </a-modal>
</template>

<script>
import { clientVisitReport } from '@/api/clientOrder'

export default {
  data() {
    return {
      noticeTitle: '确认到访',
      visible: false,
      loading: false,
      id: null,
      record: null
    }
  },
  methods: {
    showModal(record) {
      this.id = record.id
      this.record = record
      this.visible = true
    },
    handleOk() {
      this.loading = true
      const param = {
        reportId: this.id
      }
      clientVisitReport(param).then(res => {
        if (res.success) {
          this.visible = false
          this.$emit('reload')
          this.notiSuccess(this.noticeTitle, this.record.clientName + '确认到访！')
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.record = null
    }
  }
}
</script>
