export const columns = [
    {
        title: '任务',
        dataIndex: 'task.name',
        scopedSlots: { customRender: 'task.name' }
    },
    {
        title: '楼盘',
        dataIndex: 'houseName',
        scopedSlots: { customRender: 'houseName' }
    },
    {
        title: '状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: '新客户',
        dataIndex: 'clientName',
        scopedSlots: { customRender: 'clientName' }
    },
    {
        title: '手机号',
        dataIndex: 'clientPhone',
        scopedSlots: { customRender: 'clientPhone' }
    },
    {
        title: '老客户',
        dataIndex: 'oldClientName',
        scopedSlots: { customRender: 'oldClientName' }
    },
    {
        title: '手机号',
        dataIndex: 'oldClientPhone',
        scopedSlots: { customRender: 'oldClientPhone' }
    },
    {
        title: '老客户已购买楼盘',
        dataIndex: 'oldClientHouseName',
        scopedSlots: { customRender: 'oldClientRoom' }
    },
    {
        title: '老客户已购买房号',
        dataIndex: 'oldClientRoom',
        scopedSlots: { customRender: 'oldClientRoom' }
    },
    {
        title: '推荐时间',
        dataIndex: 'createTime',
        sorter: true,
        scopedSlots: { customRender: 'createTime' }
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' }
    }
]

export const statusMap = {
    '-1': {
        text: '退房',
        color: '#f50'
    },
    '-2': {
        text: '已过到访保护期',
        color: '#f50'
    },
    '-3': {
        text: '未到访已作废',
        color: '#f50'
    },
    0: {
        text: '已推荐',
        color: 'green'
    },
    1: {
        text: '已到访',
        color: 'green'
    },
    2: {
        text: '已认购',
        color: 'green'
    },
    3: {
        text: '已签约',
        color: '#87d068'
    },
    4: {
        text: '结佣中',
        color: 'cyan'
    },
    5: {
        text: '已结佣',
        color: '#2db7f5'
    }
}

export const statusSwitch = [
    {
        value: '0',
        text: '已推荐'
    },
    {
        value: '1',
        text: '已到访'
    },
    {
        value: '2',
        text: '已认购'
    },
    {
        value: '3',
        text: '已签约'
    },
    {
        value: '4',
        text: '结佣中'
    },
    {
        value: '5',
        text: '已结佣'
    },
    // {
    //     value: '-1',
    //     text: '已退房'
    // },
    {
        value: '-2',
        text: '已过到访保护期'
    },
    {
        value: '-3',
        text: '未到访已作废'
    }
]
