<template>
  <a-drawer
    :title="task.name | textFilter"
    placement="right"
    :width="drawerWidth"
    class="myDescription"
    :closable="true"
    :visible="visible"
    @close="handleCancel"
  >
    <a-card :bordered="false">
      <a-spin :spinning="loading">
        <a-descriptions title="老客户信息信息">
          <a-descriptions-item label="老客户姓名" span="3">{{
            model.oldClientName | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="手机号" span="3">{{
            model.oldClientPhone | phoneFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="楼盘" span="3">{{
            model.oldClientHouseName | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="房号" span="3">{{
            model.oldClientRoom | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="创建时间" span="3">{{
            model.createTime | textFilter
          }}</a-descriptions-item>
        </a-descriptions>

        <a-descriptions title="新客户信息">
          <a-descriptions-item label="姓名" span="3">{{
            model.clientName | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="手机号" span="3">{{
            model.clientPhone | phoneFilter
          }}</a-descriptions-item>
        </a-descriptions>

        <a-descriptions v-if="statusJudge(model, 0)" title="到访信息">
          <a-descriptions-item label="到访时间" span="3">{{
            model.acceptTime | textFilter
          }}</a-descriptions-item>
        </a-descriptions>

        <a-descriptions v-if="statusJudge(model, 1)" title="认购信息">
          <a-descriptions-item label="置业顾问" span="3">{{
            model.consultantName | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="认购房号" span="3">{{
            model.room | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="房屋总价" span="3">{{
            model.price | moneyFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="认购备注" span="3">{{
            model.subRemark | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="认购时间" span="3">{{
            model.subDate | textFilter
          }}</a-descriptions-item>
        </a-descriptions>

        <a-descriptions v-if="statusJudge(model, 2)" title="签约信息">
          <a-descriptions-item label="签约房号" span="3">{{
            model.contractRoom | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="签约总价" span="3">{{
            model.contractPrice | moneyFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="签约备注" span="3">{{
            model.contractRemark | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="暂定佣金" span="3">{{
            model.earnest | moneyFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="签约时间" span="3">{{
            model.contractTime | textFilter
          }}</a-descriptions-item>
        </a-descriptions>

        <a-descriptions v-if="statusJudge(model, 3)" title="结佣中信息">
          <a-descriptions-item label="结佣中备注" span="3">{{
            model.commissionRemark | textFilter
          }}</a-descriptions-item>
        </a-descriptions>

        <a-descriptions v-if="statusJudge(model, 4)" title="已结佣信息">
          <a-descriptions-item label="已结佣备注" span="3">{{
            model.commissionFinishRemark | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="确定佣金" span="3">{{
            model.commission | moneyFilter
          }}</a-descriptions-item>
        </a-descriptions>
      </a-spin>
    </a-card>
  </a-drawer>
</template>

<script>
import { clientOrderGet } from '@/api/clientOrder'
export default {
  props: {
    media: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      noticeTitle: '老带新管理',
      noText: '暂无信息',
      visible: false,
      loading: false,
      id: null,
      model: {},
      task: {},
      form: this.$form.createForm(this)
    }
  },
  created() {},
  methods: {
    showModal(record) {
      this.id = record.id
      this.visible = true
      this.getData()
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = {}
    },
    getData() {
      this.loading = true
      clientOrderGet(this.id).then(res => {
        if (res.id) {
          this.model = res
          this.task = res._embedded.task
        }
      }).finally(() => {
        this.loading = false
      })
    },
    statusJudge(model, status) {
      return model ? model.status > status : false
    }
  }
}
</script>
