<template>
  <a-modal
    title="老带新订单"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="handleOk">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="置业顾问">
          <a-select
            :autoFocus="true"
            allow-clear
            label-in-value
            show-search
            placeholder="选择置业顾问"
            style="width: 100%"
            @change="handleChange"
            option-label-prop="label"
            v-decorator="['consultant', { rules: [{ required: true, message: '请选择置业顾问' }] }]"
          >
            <a-select-option v-for="item in fetch" :key="item.value" :value="item.value" :label="item.name">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="认购房号">
          <a-input
            placeholder="请输入认购房号"
            v-decorator="['room', { rules: [{ required: true, message: '请输入认购房号' }] }]"
            allow-clear
            :maxLength="limitLength"
          ></a-input>
        </a-form-item>
        <a-form-item label="房屋总价(元)">
          <a-input-number
            :min="0"
            placeholder="请输入房屋总价"
            v-decorator="['price', { rules: [{ required: true, message: '请输入房屋总价' }] }]"
            allow-clear
            :maxLength="limitLength"
          ></a-input-number>
        </a-form-item>
        <a-form-item label="认购时间">
          <a-date-picker
            placeholder="请选择认购时间"
            show-time
            v-decorator="['subDate', { rules: [{ required: true, message: '请选择认购时间' }] }]"
          />
        </a-form-item>
        <a-form-item label="认购备注">
          <a-textarea
            placeholder="请输入认购备注"
            v-decorator="['subRemark']"
            allow-clear
            :maxLength="areaLimitLength"
            :rows="areaLimitRows"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { clientSubReport } from '@/api/clientOrder'
import { queryConsultant } from '@/api/consultant'

// 表单字段
const fields = ['room', 'price', 'subDate', 'subRemark']

export default {
  data() {
    return {
      noticeTitle: '老带新订单管理',
      visible: false,
      loading: false,
      id: null,
      model: null,
      record: null,
      fetch: [],
      form: this.$form.createForm(this)
    }
  },
  computed: {
    houseId() {
      return this.$store.state.house.houseId
    }
  },
  created() {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    showModal(record = null) {
      this.id = record.id
      this.record = record
      this.model = null
      this.visible = true
      this.loadData()
    },
    handleOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          values.consultant = {
            id: values.consultant.key.split(',')[1]
          }
          this.loading = true
          const param = { ...values, id: this.id }
          param.subDate = this.moment(param.subDate._d).format('YYYY-MM-DD HH:mm:ss')
          this.newInfo(param)
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = null
      this.form.resetFields()
    },
    loadData() {
      this.loading = true
      this.form.setFieldsValue({
        consultant: []
      })
      const param = { houseId: this.houseId, ...this.maxPagination }
      queryConsultant(param)
        .then(res => {
          if (res.success) {
            this.fetch = res.data.content.map(item => {
              return {
                name: item.name,
                text: `${item.name}(${item.phone})`,
                value: item.name + ',' + item.id,
                phone: item.phone
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleChange(value) {
      this.form.setFieldsValue({
        consultant: value
      })
    },
    newInfo(params) {
      setTimeout(() => {
        if (this.visible) {
          console.log(params, 'params');
          clientSubReport(params)
            .then(res => {
              if (res.success) {
                this.visible = false
                this.notiSuccess(this.noticeTitle, this.record.clientName + '认购成功')
                this.$emit('reload')
                this.form.resetFields()
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    }
  }
}
</script>
