import request from '@/utils/request'

const clientOrderApi = {
    clientOrders: '/api/v1/rest/clientOrders',

    // 老带新相关接口
    create: '/api/v1/clientOrder/add',
    query: '/api/v1/clientOrder/query',
    return: '/api/v1/clientOrder/return',
    signReport: '/api/v1/clientOrder/signReport',
    subReport: '/api/v1/clientOrder/subReport',
    visitReport: '/api/v1/clientOrder/visitReport',
    statistics: '/api/v1/clientOrder/statistics',
    export: '/api/v1/clientOrder/export',
    delete: '/api/v1/clientOrder/delete',
    commissionReport: '/api/v1/clientOrder/commissioningReport',
    commissionFinishReport: '/api/v1/clientOrder/commissionFinishReport'
}

export function clientOrders(param) {
    const url = request.requestFormat(clientOrderApi.clientOrders, param)
    return request({
        url,
        method: 'get'
    })
}

export function clientOrderAdd(data) {
    return request({
        url: clientOrderApi.clientOrders,
        method: 'post',
        data
    })
}

export function clientOrderGet(id) {
    return request({
        url: clientOrderApi.clientOrders + `/${id}`,
        method: 'get'
    })
}

export function clientOrderPut(id, data) {
    return request({
        url: clientOrderApi.clientOrders + `/${id}`,
        method: 'put',
        data
    })
}

export function clientOrderEdit(id, data) {
    return request({
        url: clientOrderApi.clientOrders + `/${id}`,
        method: 'patch',
        data
    })
}

export function clientOrderDelete(param) {
    const url = request.requestFormat(clientOrderApi.delete, param)
    return request({
        url,
        method: 'get'
    })
}

export function clientOrderReallyDelete(param) {
    return request({
        url: clientOrderApi.clientOrders + `/${param.id}`,
        method: 'delete'
    })
}

export function clientOrderQuery(param) {
    const url = request.requestFormat(clientOrderApi.query, param)
    return request({
        url,
        method: 'get'
    })
}

export function clientOrderCreate(data) {
    return request({
        url: clientOrderApi.create,
        method: 'post',
        data
    })
}

export function clientOrderStatistics(param) {
    const url = request.requestFormat(clientOrderApi.statistics, param)
    return request({
        url,
        method: 'get'
    })
}

export function clientOrderExport(param) {
    const url = request.requestFormat(clientOrderApi.export, param)
    return request({
        url,
        method: 'get'
    })
}

export function commissionReport(param) {
    const url = request.requestFormat(clientOrderApi.commissionReport, param)
    return request({
        url,
        method: 'get'
    })
}

export function commissionFinishReport(param) {
    const url = request.requestFormat(clientOrderApi.commissionFinishReport, param)
    return request({
        url,
        method: 'get'
    })
}

export function clientRetrun(param) {
    const url = request.requestFormat(clientOrderApi.return, param)
    return request({
        url,
        method: 'get'
    })
}

export function clientSubReport(data) {
    return request({
        url: clientOrderApi.subReport,
        method: 'post',
        data
    })
}

export function clientSignReport(data) {
    return request({
        url: clientOrderApi.signReport,
        method: 'post',
        data
    })
}

export function clientVisitReport(param) {
    const url = request.requestFormat(clientOrderApi.visitReport, param)
    return request({
        url,
        method: 'get'
    })
}
