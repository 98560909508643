<template>
  <a-modal
    title="签约"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="handleOk">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="签约房号">
          <a-input
            :autoFocus="true"
            placeholder="请输入签约房号"
            v-decorator="['contractRoom', { rules: [{ required: true, message: '请输入签约房号' }] }]"
            allow-clear
            :maxLength="limitLength"
          ></a-input>
        </a-form-item>
        <a-form-item label="房屋签约总价(元)">
          <a-input-number
            :min="0"
            placeholder="请输入房屋签约总价"
            v-decorator="['contractPrice', { rules: [{ required: true, message: '请输入房屋签约总价' }] }]"
            allow-clear
            :maxLength="limitLength"
          ></a-input-number>
        </a-form-item>
        <a-form-item label="暂定佣金(元)">
          <a-input-number
            :min="0"
            placeholder="请输入暂定佣金"
            v-decorator="['earnest', { rules: [{ required: true, message: '请输入暂定佣金' }] }]"
            allow-clear
            :maxLength="limitLength"
          ></a-input-number>
        </a-form-item>
        <a-form-item label="签约时间">
          <a-date-picker
            placeholder="请选择签约时间"
            show-time
            v-decorator="['contractTime', { rules: [{ required: true, message: '请选择签约时间' }] }]"
          />
        </a-form-item>
        <a-form-item label="签约备注">
          <a-textarea
            placeholder="请输入签约备注"
            v-decorator="['contractRemark']"
            allow-clear
            :maxLength="areaLimitLength"
            :rows="areaLimitRows"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { clientSignReport } from '@/api/clientOrder'

// 表单字段
const fields = ['contractRoom', 'contractPrice', 'earnest', 'contractTime', 'contractRemark']

export default {
  data() {
    return {
      noticeTitle: '老带新订单管理',
      visible: false,
      loading: false,
      id: null,
      model: null,
      form: this.$form.createForm(this)
    }
  },
  created() {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    showModal(record = null) {
      this.id = record.id
      this.model = null
      this.visible = true
    },
    handleOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.loading = true
          const param = { ...values, id: this.id }
          if (param.contractTime) {
            param.contractTime = this.moment(param.contractTime).format('YYYY-MM-DD HH:mm:ss')
          }
          this.newInfo(param)
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = null
      this.form.resetFields()
    },
    newInfo(params) {
      setTimeout(() => {
        if (this.visible) {
          clientSignReport(params)
            .then(res => {
              if (res.success) {
                this.visible = false
                this.$emit('reload')
                this.notiSuccess(this.noticeTitle, this.record.oldClientName + '结佣中')
                this.form.resetFields()
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    }
  }
}
</script>
