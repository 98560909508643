<template>
  <page-header-wrapper>
    <m-statictisc ref="MStatictisc"></m-statictisc>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="新客户">
                <a-input v-model="queryParam.clientName" allow-clear placeholder="请输入新客户" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="时间段">
                <a-range-picker v-model="visitTime" @change="timeChange" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="状态">
                <a-select placeholder="请选择" allow-clear v-model="queryParam.status" style="width: 100%;" @change="sChange">
                  <a-select-option v-for="status in statusSwitch" :key="status.id" :value="status.value">
                    {{ status.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetReload">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="download" @click="exportReport">导出</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="loadData"
        :scroll="defaultScroll"
        :alert="true"
        :totalCount="totalCount"
        showPagination="auto"
      >
        <span slot="clientName" slot-scope="text" class="myCol">
          <span>{{ text }}</span>
        </span>
        <span slot="status" slot-scope="text, record">
          <a-tag :color="text | statusTypeFilter" class="myPointer" @click="statusChange(record)">
            {{ text | statusFilter }}
          </a-tag>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="lookInfo(record)">查看</a>
          </template>
        </span>
      </s-table>

      <row-drawer ref="createDrawer"></row-drawer>
    </a-card>

    <report-visit ref="ReportVisit" @reload="reload"></report-visit>
    <report-sub ref="ReportSub" @reload="reload"></report-sub>
    <report-sign ref="ReportSign" @reload="reload"></report-sign>
    <report-commission ref="ReportCommission" @reload="reload"></report-commission>
    <finish-commission ref="FinishCommission" @reload="reload"></finish-commission>
  </page-header-wrapper>
</template>

<script>
import moment from 'moment'
import { STable, Ellipsis } from '@/components'
import { clientOrderQuery } from '@/api/clientOrder'
import { columns, statusMap, statusSwitch } from './js/manage'

import RowDrawer from './modules/RowDrawer'
import MStatictisc from './modules/MStatictisc'
import ReportVisit from './modules/ReportVisit'
import ReportSub from './modules/ReportSub'
import ReportSign from './modules/ReportSign'
import ReportCommission from './modules/ReportCommission'
import FinishCommission from './modules/FinishCommission'

export default {
  name: 'Number',
  components: {
    STable,
    Ellipsis,
    RowDrawer,
    MStatictisc,

    ReportVisit,
    ReportSub,
    ReportSign,
    ReportCommission,
    FinishCommission
  },
  data() {
    return {
      noticeTitle: '老带新管理',
      totalCount: 0,
      exportApi: '/clientOrder/export',
      // js
      columns,
      statusMap,
      statusSwitch,
      queryParam: {},
      visitTime: null,
      loadData: parameter => {
        const param = {
          houseId: this.houseId,
          developerId: this.userId
        }
        const params = this.paramFormat(parameter, param)
        Object.assign(params, this.queryParam)
        return clientOrderQuery(params).then(res => {
          if (res.success) {
            const result = this.resFormat(res)
            this.totalCount = result.totalCount
            return result
          }
        })
      }
    }
  },
  computed: {
    houseId() {
      return this.$store.state.house.houseId
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].color
    }
  },
  methods: {
    reload() {
      this.$refs.MStatictisc.reload()
      this.tableHome()
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.visitTime = null
      this.reload()
    },
    exportReport() {
      const url = `${window.location.origin}/api/v1${this.exportApi}`
      const param = {
        houseId: this.houseId,
        developerId: this.userId,
        sort: 'createTime,desc'
      }
      Object.assign(param, this.queryParam)
      window.location.href = this.requestFormat(url, param)
    },
    lookInfo(record) {
      this.$refs.createDrawer.showModal(record)
    },
    statusChange(record) {
      if (record.status === 0) this.refShow('ReportVisit', record)
      if (record.status === 1) this.refShow('ReportSub', record)
      if (record.status === 2) this.refShow('ReportSign', record)
      if (record.status === 3) this.refShow('ReportCommission', record)
      if (record.status === 4) this.refShow('FinishCommission', record)
      if (record.status === 5) {
        this.$notification.success({
          message: this.noticeTitle,
          description: '已结佣'
        })
      }
    },
    sChange(value) {
      console.log(value);
      if (value === undefined) {
        delete this.queryParam.status
      }
    },
    refShow(type, record) {
      this.$refs[type].showModal(record)
    },
    timeChange(value) {
      if (value.length === 0) {
        delete this.queryParam.startTime
        delete this.queryParam.endTime
      } else {
        this.queryParam.startTime = moment(value[0]._d).format('YYYY-MM-DD 00:00:00')
        this.queryParam.endTime = moment(value[1]._d).format('YYYY-MM-DD 00:00:00')
      }
    }
  }
}
</script>
