<template>
  <a-modal
    title="结佣中"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="handleOk">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="结佣中备注">
          <a-textarea
            :autoFocus="true"
            placeholder="请输入结佣中备注"
            v-decorator="['commissionRemark', { rules: [{ required: true, message: '请输入结佣中备注' }] }]"
            allow-clear
            :maxLength="areaLimitLength"
            :rows="areaLimitRows"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { commissionReport } from '@/api/clientOrder'

// 表单字段
const fields = ['commissionRemark']

export default {
  data() {
    return {
      noticeTitle: '老带新订单管理',
      visible: false,
      loading: false,
      id: null,
      model: null,
      form: this.$form.createForm(this)
    }
  },
  created() {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    showModal(record = null) {
      this.id = record.id
      this.model = null
      this.visible = true
    },
    handleOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.loading = true
          const param = {
            reportId: this.id
          }
          values.commissionRemark = encodeURIComponent(values.commissionRemark)
          console.log(values, 'form values')
         // values = escape(values)
          const params = Object.assign(param, values)
          this.newInfo(params)
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = null
      this.form.resetFields()
    },
    newInfo(params) {
      setTimeout(() => {
        if (this.visible) {
          commissionReport(params)
            .then(res => {
              if (res.success) {
                this.visible = false
                this.$emit('reload')
                this.notiSuccess(this.noticeTitle, '新建成功')
                this.form.resetFields()
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    }
  }
}
</script>
